package tta.destinigo.talktoastro.feature_call_consultation.timer

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch

class CountdownTimer(
    private val durationMillis: Long,  // Total time in milliseconds
    private val intervalMillis: Long = 1000L,  // Interval for ticking, default 1 second
    private val onTickPop: ((Long) -> Unit)? = null,
    private val onFinishPop: (() -> Unit)? = null
) {
    private val _timeLeft = MutableStateFlow(durationMillis)
    val timeLeft: StateFlow<Long> = _timeLeft

    private var job: Job? = null

    // Callback for every tick
    var onTick: ((Long) -> Unit)? = null

    // Callback for when the timer finishes
    var onFinish: (() -> Unit)? = null

    init {
        setLog("Countdown Timer Init")
        setLog("durationMillis -> $durationMillis")
    }

    fun start() {
        // Cancel any existing job
        job?.cancel()

        // Launch a new job in the Main dispatcher
        job = CoroutineScope(Dispatchers.Main).launch {
            while (_timeLeft.value > 0) {
                delay(intervalMillis)
                _timeLeft.value = (_timeLeft.value - intervalMillis).coerceAtLeast(0L)
                onTick?.invoke(_timeLeft.value)  // Call the onTick callback
                onTickPop?.invoke(_timeLeft.value)
                setLog("Countdown Timer -> ${_timeLeft.value}")
            }
            onFinish?.invoke()  // Call the onFinish callback when done
            onFinishPop?.invoke()
        }
    }

    fun pause() {
        job?.cancel()
    }

    fun reset() {
        pause()
        _timeLeft.value = durationMillis
    }

    fun stop() {
        pause()
        _timeLeft.value = 0L
    }

    // Cancel any ongoing job when the object is no longer used
    fun cleanup() {
        pause()
    }
}

fun setLog(msg:String){
    co.touchlab.kermit.Logger.d("Countdown Timer -> $msg")
}

fun formatTime(timeMillis: Long): String {
    val totalSeconds = (timeMillis / 1000).toInt()
    val seconds = totalSeconds % 60
    val minutes = (totalSeconds / 60) % 60
    val hours = totalSeconds / 3600

    return "${hours.toString().padStart(2, '0')}:${
        minutes.toString().padStart(2, '0')
    }:${seconds.toString().padStart(2, '0')}"
}