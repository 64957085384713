package tta.destinigo.talktoastro.feature_call_consultation.persentation.call_starter

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Call
import androidx.compose.material.icons.filled.Person
import androidx.compose.material.icons.filled.Timer
import androidx.compose.material.icons.filled.Wallet
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.ModalBottomSheet
import androidx.compose.material3.Text
import androidx.compose.material3.rememberModalBottomSheetState
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import tta.destinigo.talktoastro.feature_call_consultation.data.response.CallDurationData
import tta.destinigo.talktoastro.feature_call_consultation.persentation.call_bottom_sheet_info.CallConsultationComponent
import tta.destinigo.talktoastro.feature_call_consultation.persentation.call_bottom_sheet_info.CallConsultationEvent
import tta.destinigo.talktoastro.feature_call_consultation.persentation.call_consultation_options.CallConsultationOptionScreen
import tta.destinigo.talktoastro.feature_call_consultation.persentation.call_consultation_options.CallConsultationOptionsComponent
import kotlinx.coroutines.launch
import tta.destinigo.talktoastro.shared.persentation.ExpertProfileIcon
import tta.destinigo.talktoastro.shared.theme.Scale
import tta.destinigo.talktoastro.shared.theme.initializeCurrency
import tta.destinigo.talktoastro.shared.theme.let_s_connect
import tta.destinigo.talktoastro.shared.theme.maximum_call_duration
import tta.destinigo.talktoastro.shared.theme.pricing
import tta.destinigo.talktoastro.shared.theme.primaryColor
import tta.destinigo.talktoastro.shared.theme.wallet_balance

//@OptIn(ExperimentalMaterial3Api::class, ExperimentalMaterial3WindowSizeClassApi::class)
//@Composable
//fun CallInfoSuccessBottomSheet(
//    consultationComponent: CallConsultationComponent,
//    callDurationData: CallDurationData?,
//    onDismiss: () -> Unit
//) {
//    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
//
//    val sheetState = rememberModalBottomSheetState(skipPartiallyExpanded = true)
//    val coroutineScope = rememberCoroutineScope()
//    val state by consultationComponent.state.collectAsState()
//    val openCallOption = rememberSaveable { mutableStateOf(false) }
//
//    ModalBottomSheet(
//        sheetState = sheetState,
//        onDismissRequest = {
//            coroutineScope.launch {
//                coroutineScope.launch {
//                    sheetState.hide()
//                    onDismiss.invoke()
//                }
//            }
//        }
//    ) {
//        Column(
//            modifier = Modifier
//                .fillMaxWidth()
//                .padding(16.dp)
//                .align(Alignment.CenterHorizontally)
//        ) {
//            Box(modifier = Modifier.fillMaxWidth(), contentAlignment = Alignment.Center) {
//                Text(
//                    text = "Call Consultation",
//                )
//            }
//
//            Spacer(modifier = Modifier.height(8.dp))
//            Row(
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .height(90.dp)
////                    .padding(top = 16.dp)
//                    .clip(RoundedCornerShape(8.dp)),
////                    .background(Color.LightGray),
//                verticalAlignment = Alignment.CenterVertically
//            ) {
//                if (consultationComponent.expertImage.isEmpty()) {
//                    Image(
//                        painter = painterResource(Res.drawable.ellipse),
//                        contentDescription = null
//                    )
//                } else {
//                    AsyncImage(
//                        model = consultationComponent.expertImage,
//                        contentDescription = "expert image",
//                        modifier = Modifier
//                            .size(85.dp)
//                            .clip(CircleShape),
//                        contentScale = ContentScale.Crop
//                    )
//                }
//
//                Spacer(modifier = Modifier.width(16.dp))
//                Column {
//                    Text(
//                        text = consultationComponent.expertName,
//                        // style = MaterialTheme.typography.body1,
//                        maxLines = 1,
//                        modifier = Modifier.fillMaxWidth()
//                    )
//                    Spacer(modifier = Modifier.height(4.dp))
//                    Text(
//                        text = consultationComponent.expertSkills,
//                        // style = MaterialTheme.typography.body2,
//                        maxLines = 2,
//                        modifier = Modifier.fillMaxWidth()
//                    )
//                }
//            }
//
//            Spacer(modifier = Modifier.height(8.dp))
//            Row(
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .height(90.dp)
//                    .clip(RoundedCornerShape(8.dp)),
//                horizontalArrangement = Arrangement.SpaceBetween,
//                verticalAlignment = Alignment.CenterVertically
//            ) {
//                // Wallet Balance
//                Column(
//                    modifier = Modifier.weight(1f)
//                ) {
//
//                    Row {
//                        Box(modifier = Modifier.background(primaryLight50)) {
//                            Image(
//                                painter = painterResource(Res.drawable.ic_wallet),
//                                contentDescription = null,
//                                modifier = Modifier.height(35.dp).width(35.dp)
//                            )
//                        }
//                        Spacer(modifier = Modifier.width(8.dp))
//                        Column {
//                            Text(
//                                text = wallet_balance,
//                                fontSize = 12.sp,
//                                // style = MaterialTheme.typography.body1,
//                                maxLines = 1,
//                                modifier = Modifier.fillMaxWidth()
//                            )
//                            Text(
//                                //text = "₹500",
//                                // style = MaterialTheme.typography.body2,
//                                text = "$currency${callDurationData?.balance.toString()}",
//                                maxLines = 1,
//                                modifier = Modifier.fillMaxWidth()
//                            )
//                        }
//
//                    }
//
//                }
//                // Pricing
//                Column(
//                    modifier = Modifier.weight(1f)
//                ) {
//                    Row {
//                        Box(modifier = Modifier.background(primaryLight50)) {
//                            Image(
//                                // painter = painterResource(Res.drawable.consultation_call_small_icon),
//                                imageVector = Icons.Default.Call,
//                                contentDescription = null,
//                                modifier = Modifier.size(25.dp),
//                                colorFilter = ColorFilter.tint(md_them_primary)
//                            )
//                        }
//                        Spacer(modifier = Modifier.width(8.dp))
//                        Column {
//                            Text(
//                                text = pricing,
//                                // style = MaterialTheme.typography.body1,
//                                fontSize = 12.sp,
//                                maxLines = 1,
//                                modifier = Modifier.fillMaxWidth()
//                            )
//
//                            Row(
//                                verticalAlignment = Alignment.CenterVertically,
//                                modifier = Modifier.fillMaxWidth()
//                            )
//                            {
//                                if (callDurationData?.type != null) {
//                                    Box(
//                                        modifier = Modifier
//                                            .background(primaryColor)
//                                            .padding(horizontal = 4.dp, vertical = 4.dp),
//                                    ) {
//                                        Text(
//                                            text = "NEW USER",
//                                            color = Color.White,
//                                            fontSize = 10.sp,
//                                            textAlign = TextAlign.Center,
//                                            modifier = Modifier.align(Alignment.Center)
//                                        )
//                                    }
//                                } else {
//                                    if (callDurationData?.flashOff == null || callDurationData.flashOff == 0
//                                            //.equals("0", ignoreCase = true) || callDurationData?.flashOff?.toString().equals("null",true)
//                                    ) {
//                                        Text(
//                                            text = "$currency${callDurationData?.perMinutePrice} /Min",
//                                            maxLines = 1,
//                                            modifier = Modifier.fillMaxWidth()
//                                        )
//                                    } else {
//                                        Text(
//                                            text = "$currency${callDurationData?.flashOff} /Min",
//                                            fontSize = 14.sp,
//                                            color = Color.Black,
//                                        )
//                                        Spacer(modifier = Modifier.width(5.dp))
//                                        Text(
//                                            text = "$currency${callDurationData?.perMinutePrice} /Min",
//                                            textDecoration = TextDecoration.LineThrough,
//                                            fontSize = 12.sp,
//                                            color = Color.Black,
//                                        )
//                                    }
//                                }
//                            }
//                        }
//                    }
//                }
//            }
//
//            Row(
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .clip(RoundedCornerShape(8.dp))
//                    .background(primaryLight50),
//                verticalAlignment = Alignment.CenterVertically
//            ) {
//                Image(
//                    // painter = painterResource(Res.drawable.consultation_call_small_icon),
//                    imageVector = Icons.Default.Call,
//                    modifier = Modifier.size(25.dp),
//                    contentDescription = null,
//                    colorFilter = ColorFilter.tint(md_them_primary)
//                )
//                Spacer(modifier = Modifier.width(8.dp))
//                Text(text = maximum_call_duration)
//                Spacer(modifier = Modifier.weight(1f))
//                Box(contentAlignment = Alignment.CenterEnd) {
//                    // Safely get the total call duration or default to 0 if null
//                    val totalCallDuration = callDurationData?.totalCallDuration?.toIntOrNull() ?: 0
//
//                    // Calculate minutes and seconds
//                    val minutes = totalCallDuration / 60
//                    val seconds = totalCallDuration % 60
//
//                    // Manually format the time as MM:SS
//                    val formattedTime =
//                        "${if (minutes < 10) "0" else ""}$minutes:${if (seconds < 10) "0" else ""}$seconds"
//
//                    // Display the formatted time in the Text composable
//                    Text(
//                        text = "$formattedTime Min",
//                        textAlign = TextAlign.End
//                    )
//                }
//
//            }
//
//            Spacer(modifier = Modifier.height(10.dp))
//
//            if (callDurationData?.type != null) {
//                Box(
//                    modifier = Modifier.fillMaxWidth(),
//                    contentAlignment = Alignment.Center
//                ) {
//                    Text(
//                        text = "This is a one-time free call offered by Winyway",
//                        fontSize = 12.sp,
//                        textAlign = TextAlign.Center
//                    )
//                }
//            }
//
//            Spacer(modifier = Modifier.height(10.dp))
//
//            Button(
//                onClick = {
//
//                    openCallOption.value = true
//                },
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .height(55.dp)
//                    .padding(bottom = 8.dp),
//                shape = RoundedCornerShape(5.dp), // Set the shape to RectangleShape
//                colors = ButtonDefaults.buttonColors(md_them_primary)
//            ) {
//                Text(text = let_s_connect, color = Color.White)
//            }
//
//            Button(
//                onClick = { consultationComponent.event(CallConsultationEvent.onNavigateChoseRechargePack) },
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .height(55.dp)
//                    .padding(bottom = 8.dp),
//                shape = RoundedCornerShape(5.dp), // Set the shape to RectangleShape
//                colors = ButtonDefaults.buttonColors(Color.Transparent),
//                border = BorderStroke(1.dp, md_them_primary)
//            ) {
//                Text(text = "Low Balance? Recharge Wallet", color = md_them_primary)
//            }
//        }
//    }
//
//    if (
//        openCallOption.value
//    ) {
//        coroutineScope.launch {
//            sheetState.hide()
//        }
//        CallConsultationOptionScreen(
//            CallConsultationOptionsComponent(
//                context = consultationComponent,
//                _expertId = consultationComponent.expertId,
//                _expertName = consultationComponent.expertName,
//                _expertSkills = consultationComponent.expertSkills,
//                _expertImage = consultationComponent.expertImage,
//                onClosePage = {
//                    openCallOption.value = false
//                    consultationComponent.onClosePage()
//                },
//                onCallWaittingPage = {
//                    openCallOption.value = false
//                    consultationComponent.onClickLetsConnectButton()
//                },
//                onInternetCalling = {
//                    consultationComponent.onVoIpCallStart(callDurationData?.totalCallDuration.toString())
//                },
//            )
//        )
//    }
//
//}


@OptIn(ExperimentalMaterial3Api::class, ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun CallInfoSuccessBottomSheet(
    consultationComponent: CallConsultationComponent,
    callDurationData: CallDurationData?,
    onDismiss: () -> Unit
) {
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val sheetState = rememberModalBottomSheetState(skipPartiallyExpanded = true)
    val coroutineScope = rememberCoroutineScope()
    val state by consultationComponent.state.collectAsState()
    val openCallOption = rememberSaveable { mutableStateOf(false) }

    val content: @Composable () -> Unit = {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(20.dp)
            // .align(Alignment.CenterHorizontally)
        ) {
            Box(modifier = Modifier.fillMaxWidth(), contentAlignment = Alignment.Center,) {
                Text(
                    text = "Call Consultation",
                )
            }

            Spacer(modifier = Modifier.height(8.dp))
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .border(
                        width = 2.dp, // Thickness of the border
                        color = primaryColor.copy(alpha = 0.15f), // Color of the stroke
                        shape = RoundedCornerShape(8.dp) // Ensuring the border has rounded corners
                    )
                    .clip(RoundedCornerShape(8.dp))
                    .padding(12.dp),
                verticalAlignment = Alignment.CenterVertically
            ) {
                if (consultationComponent.expertImage.isEmpty()) {
                    Icon(
                        imageVector = Icons.Default.Person,
                        contentDescription = null
                    )
                } else {
//                    AsyncImage(
//                        model = consultationComponent.expertImage,
//                        contentDescription = "expert image",
//                        modifier = Modifier
//                            .size(85.dp)
//                            .clip(CircleShape),
//                        contentScale = ContentScale.Crop
//                    )

                    ExpertProfileIcon(
                        profileImageUrl = consultationComponent.expertImage,
                        modifier = Modifier.size(50.dp)
                    )
                }

                Spacer(modifier = Modifier.width(16.dp))
                Column {
                    Text(
                        text = consultationComponent.expertName,
                        maxLines = 1,
                        modifier = Modifier.fillMaxWidth()
                    )
                    Spacer(modifier = Modifier.height(4.dp))
                    Text(
                        text = consultationComponent.expertSkills,
                        maxLines = 2,
                        modifier = Modifier.fillMaxWidth()
                    )
                }
            }

            Spacer(modifier = Modifier.height(8.dp))
            // Wallet Balance and Pricing Section
            WalletAndPricingSection(consultationComponent, callDurationData)

            // Call Duration Section
            CallDurationSection(callDurationData)

            Spacer(modifier = Modifier.height(10.dp))

            if (callDurationData?.type != null) {
                Box(
                    modifier = Modifier.fillMaxWidth(),
                    contentAlignment = Alignment.Center
                ) {
                    Text(
                        text = "This is a one-time free call offered by Winyway",
                        fontSize = 12.sp,
                        textAlign = TextAlign.Center
                    )
                }
            }

            Spacer(modifier = Modifier.height(10.dp))

            Button(
                onClick = {
                    openCallOption.value = true
                    //onDismiss.invoke()
                    //consultationComponent.onClosePage()
                },
                modifier = Modifier
                    .fillMaxWidth()
                    .height(55.dp)
                    .padding(bottom = 8.dp),
                shape = RoundedCornerShape(5.dp),
                colors = ButtonDefaults.buttonColors(primaryColor)
            ) {
                Text(text = let_s_connect, color = Color.White)
            }

            Button(
                onClick = {
                    consultationComponent.event(CallConsultationEvent.onNavigateChoseRechargePack)
                    //onDismiss.invoke()
                    //consultationComponent.onClosePage()
                },
                modifier = Modifier
                    .fillMaxWidth()
                    .height(55.dp)
                    .padding(bottom = 8.dp),
                shape = RoundedCornerShape(5.dp),
                colors = ButtonDefaults.buttonColors(Color.Transparent),
                border = BorderStroke(1.dp, primaryColor)
            ) {
                Text(text = "Low Balance? Recharge Wallet", color = primaryColor)
            }
        }
    }

    if (windowSizeClass == WindowWidthSizeClass.Compact) {
        // Use BottomSheet for Compact screens
        ModalBottomSheet(
            sheetState = sheetState,
            containerColor = Color.White,
            onDismissRequest = {
                coroutineScope.launch {
                    sheetState.hide()
                    onDismiss()
                }
            }
        ) {
            content()
        }
    } else {
        // Use Dialog for larger screens

//        AlertDialog(onDismissRequest = { onDismiss() }) {
//            Surface(
//                //shape = RoundedCornerShape(Scale.CORNER_MEDIUM),
//                shape = RoundedCornerShape(Scale.CORNER_SMALL),
//                color = Color.White,
//                modifier = Modifier.padding(16.dp).background(Color.White)
//            ) {
//                content()
//            }
//        }
        AlertDialog(
            containerColor = Color.White,
            onDismissRequest = {
                consultationComponent.onClosePage()
                onDismiss.invoke()
            },
            text = content,
            shape = RoundedCornerShape(Scale.CORNER_SMALL),
            confirmButton = {
//                coroutineScope.launch {
//                    sheetState.hide()
//                }
            },
            dismissButton = {

//                coroutineScope.launch {
//                    sheetState.hide()
//                }
            }
        )
    }

    if (openCallOption.value) {
        coroutineScope.launch {
            sheetState.hide()
           // onDismiss.invoke()
        }
        CallConsultationOptionScreen(
            CallConsultationOptionsComponent(
                context = consultationComponent,
                _expertId = consultationComponent.expertId,
                _expertName = consultationComponent.expertName,
                _expertSkills = consultationComponent.expertSkills,
                _expertImage = consultationComponent.expertImage,
                onClosePage = {
                    openCallOption.value = false
                    consultationComponent.onClosePage()
                },
                onCallWaittingPage = {
                    openCallOption.value = false
                    consultationComponent.onClickLetsConnectButton()
                },
                onInternetCalling = {
                    consultationComponent.onVoIpCallStart(callDurationData?.totalCallDuration.toString())
                },
            )
        )
    }
}

@Composable
fun WalletAndPricingSection(
    consultationComponent: CallConsultationComponent,
    callDurationData: CallDurationData?
) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .height(90.dp)
            .clip(RoundedCornerShape(8.dp)),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        // Wallet Balance
//        Column(modifier = Modifier.weight(1f)) {
//            Row {
//                Box(modifier = Modifier.background(primaryLight50)) {
//                    Image(
//                        painter = painterResource(Res.drawable.ic_wallet),
//                        contentDescription = null,
//                        modifier = Modifier.height(35.dp).width(35.dp)
//                    )
//                }
//                Spacer(modifier = Modifier.width(8.dp))
//                Column {
//                    Text(
//                        text = wallet_balance,
//                        fontSize = 12.sp,
//                        maxLines = 1,
//                        modifier = Modifier.fillMaxWidth()
//                    )
//                    Text(
//                        text = "$currency${callDurationData?.balance.toString()}",
//                        maxLines = 1,
//                        modifier = Modifier.fillMaxWidth()
//                    )
//                }
//            }
//        }

        // Wallet Balance
        Row(
            verticalAlignment = Alignment.CenterVertically, modifier = Modifier.weight(1f)
        ) {
            Box(
                modifier = Modifier.background(
                    primaryColor.copy(alpha = 0.15F),
                    shape = RoundedCornerShape(Scale.CORNER_SMALL)
                ).padding(8.dp)
            ) {
                Icon(
                    imageVector = Icons.Default.Wallet,
                    contentDescription = null,
                    modifier = Modifier.size(Scale.GLOBAL_ICON_SIZE_MAX),
                    tint = primaryColor,
                )
            }
            Spacer(modifier = Modifier.width(8.dp))
            Column {
                Text(
                    text = wallet_balance, style = MaterialTheme.typography.labelSmall
                )
                Text(
                    text = "${initializeCurrency()}${callDurationData?.balance.toString()}",
                    style = MaterialTheme.typography.titleSmall
                )

//                Text(
//                    text = "${initializeCurrency()}${callDurationData?.balance.toString()}",
//                    style = MaterialTheme.typography.titleSmall
//                )
            }
        }

        // Pricing
        Column(modifier = Modifier.weight(1f)) {
            Row {
                Box(
                    modifier = Modifier.background(
                        primaryColor.copy(alpha = 0.15F),
                        shape = RoundedCornerShape(Scale.CORNER_SMALL)
                    ).padding(8.dp)
                ) {
                    Image(
                        imageVector = Icons.Default.Call,
                        contentDescription = null,
                        modifier = Modifier.size(Scale.GLOBAL_ICON_SIZE_MAX),
                        colorFilter = ColorFilter.tint(primaryColor)
                    )
                }
                Spacer(modifier = Modifier.width(8.dp))
                Column {
                    Text(
                        text = pricing,
                        fontSize = 12.sp,
                        maxLines = 1,
                        modifier = Modifier.fillMaxWidth()
                    )
                    // Pricing Info
                    PricingInfo(callDurationData)
                }
            }
        }
    }
}

@Composable
fun CallDurationSection(callDurationData: CallDurationData?) {
    Box(
        modifier = Modifier.background(
            primaryColor.copy(alpha = 0.15F), shape = RoundedCornerShape(Scale.CORNER_SMALL)
        ).fillMaxWidth().wrapContentHeight(),
    ) {
        Row(
            modifier = Modifier.fillMaxWidth().padding(12.dp),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Icon(
                modifier = Modifier.size(Scale.GLOBAL_ICON_SIZE_MAX),
                imageVector = Icons.Default.Timer,
                contentDescription = null,
                tint = primaryColor
            )
            Spacer(modifier = Modifier.width(8.dp))
            Text(text = maximum_call_duration)
            Spacer(modifier = Modifier.weight(1f))
            Box(contentAlignment = Alignment.CenterEnd) {
                val totalCallDuration = callDurationData?.totalCallDuration?.toIntOrNull() ?: 0
                val minutes = totalCallDuration / 60
                val seconds = totalCallDuration % 60
                val formattedTime =
                    "${if (minutes < 10) "0" else ""}$minutes:${if (seconds < 10) "0" else ""}$seconds"
                Text(text = "$formattedTime Min", textAlign = TextAlign.End)
            }
        }
    }
}

@Composable
fun PricingInfo(callDurationData: CallDurationData?) {
    Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.fillMaxWidth()) {
        if (callDurationData?.type != null) {
            Box(
                modifier = Modifier
                    .background(primaryColor)
                    .padding(horizontal = 4.dp, vertical = 4.dp)
            ) {
                Text(
                    text = "NEW USER",
                    color = Color.White,
                    fontSize = 10.sp,
                    textAlign = TextAlign.Center,
                    modifier = Modifier.align(Alignment.Center)
                )
            }
        } else {
            if (callDurationData?.flashOff == null || callDurationData.flashOff!!.equals("0",true)) {
                Text(text = "${initializeCurrency()}${callDurationData?.perMinutePrice} /Min", maxLines = 1)
            } else {
                Text(
                    text = "${initializeCurrency()}${callDurationData?.flashOff} /Min",
                    fontSize = 14.sp,
                    color = Color.Black
                )
                Spacer(modifier = Modifier.width(5.dp))
                Text(
                    text = "${initializeCurrency()}${callDurationData?.perMinutePrice} /Min",
                    textDecoration = TextDecoration.LineThrough,
                    fontSize = 12.sp,
                    color = Color.Black
                )
            }
        }
    }
}
