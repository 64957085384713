package tta.destinigo.talktoastro.feature_call_consultation.persentation.voip_end_page

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.outlined.StarBorder
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import tta.destinigo.talktoastro.shared.data.CommonExpertInfo
import tta.destinigo.talktoastro.shared.persentation.CommonButton
import tta.destinigo.talktoastro.shared.persentation.ExpertProfileIcon

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun ConsultationEndedPage(
    expertInfo: CommonExpertInfo,
    duration: String,
    onRatingSelected: (Int) -> Unit,
    onDoneClicked: () -> Unit
) {

    // Responsiveness and better UI scaling across platforms using WindowSizeClass
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass

    // Main Layout with padding based on screen size
    Column(
        modifier = Modifier
            .wrapContentHeight()
            .background(MaterialTheme.colorScheme.background)
            .padding(horizontal = if (windowSizeClass == WindowWidthSizeClass.Compact) 16.dp else 24.dp),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.SpaceBetween
    ) {
        // Title: Consultation Ended
        Spacer(modifier = Modifier.height(24.dp))

        Text(
            text = "Consultation Ended",
            style = MaterialTheme.typography.headlineMedium.copy(fontWeight = FontWeight.Bold),
            color = MaterialTheme.colorScheme.onBackground,
            modifier = Modifier.padding(top = 16.dp)
        )

        Spacer(modifier = Modifier.height(24.dp))

        // Expert Info: Profile Picture, Name, Duration
        ExpertInfoSection(expertInfo, duration)

        Spacer(modifier = Modifier.height(32.dp))

        // Rating Section
        RatingSection(
            onRatingSelected = onRatingSelected
        )

        Spacer(modifier = Modifier.height(48.dp))

//         Done Button
        CommonButton(
            buttonText = "Done",
            modifier = Modifier.fillMaxWidth(),
            onClick = {
                onDoneClicked.invoke()
            },
            icon = null,
        )

        Spacer(modifier = Modifier.height(24.dp))
    }
}

@Composable
fun ExpertInfoSection(expertInfo: CommonExpertInfo, duration: String) {
//     Expert Info: Image, Name, and Duration
    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {
        // Profile Image
        ExpertProfileIcon(
            profileImageUrl = expertInfo.expertProfileImageUrl,
            modifier = Modifier.size(64.dp)
        )

        Spacer(modifier = Modifier.height(8.dp))

        // Expert Name
        Text(
            text = expertInfo.expertName,
            style = MaterialTheme.typography.titleMedium,
            color = MaterialTheme.colorScheme.onBackground
        )

        // Duration
        Spacer(modifier = Modifier.height(4.dp))
        Text(
            text = "Call Duration : $duration",
            style = MaterialTheme.typography.labelSmall,
        )
    }
}

@Composable
fun RatingSection(onRatingSelected: (Int) -> Unit) {
    // Rating Prompt
    Text(
        text = "How was the consultation experience?",
        style = MaterialTheme.typography.bodyMedium,
        color = MaterialTheme.colorScheme.onBackground,
    )

    // Star Rating: Optimized with hoisted state
    Row(
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically
    ) {
        (1..5).forEach { star ->
            IconButton(onClick = { onRatingSelected(star) }) {
                Icon(
                    imageVector = Icons.Outlined.StarBorder,
                    contentDescription = "$star star",
                    tint = Color.Gray,
                    modifier = Modifier.size(24.dp)
                )
            }
        }
    }
}
