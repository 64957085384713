package tta.destinigo.talktoastro.feature_call_consultation.persentation.call_bottom_sheet_info

import tta.destinigo.talktoastro.feature_call_consultation.data.response.CallDurationData
import tta.destinigo.talktoastro.shared.data.RechargeData

sealed class CallInfoBottomSheetState {
    data class Error(val msg: String) : CallInfoBottomSheetState()
    data class Loading(val isLoading: Boolean) : CallInfoBottomSheetState()
    data class CallInfoSuccess(val data: CallDurationData) : CallInfoBottomSheetState()
    data class FreeCallLowBalance(val data: CallDurationData) : CallInfoBottomSheetState()
    data class NormalCallLowBalance(
        val data: CallDurationData,
        val rechargeData: List<RechargeData>
    ) : CallInfoBottomSheetState()
//    data class
}