package tta.destinigo.talktoastro.feature_call_consultation.persentation.expert_busy

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.ModalBottomSheet
import androidx.compose.material3.SheetState
import androidx.compose.material3.Text
import androidx.compose.material3.rememberModalBottomSheetState
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import tta.destinigo.talktoastro.feature_call_consultation.persentation.call_bottom_sheet_info.CallConsultationComponent
import tta.destinigo.talktoastro.feature_call_consultation.persentation.widget.ExpertCard
import tta.destinigo.talktoastro.shared.theme.Scale


@OptIn(ExperimentalMaterial3Api::class, ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun ErrorBottomSheetCallConsultation(msg: String, onDismiss: () -> Unit,component: CallConsultationComponent? = null) {
    val sheetState = rememberModalBottomSheetState(skipPartiallyExpanded = true)
    val coroutineScope = rememberCoroutineScope()
    // ErrorBottomSheet(msg,sheetState,onDismiss,false)
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass

    if (windowSizeClass == WindowWidthSizeClass.Compact) {
        ModalBottomSheet(
            sheetState = sheetState,
            shape = RoundedCornerShape(topStart = Scale.CORNER_SMALL, topEnd = Scale.CORNER_SMALL),
            onDismissRequest = {
                onDismiss.invoke()
            }) { ErrorBottomSheet(msg, sheetState, onDismiss, false,component!!) }
    } else {
        AlertDialog(
            containerColor = Color.White,
            onDismissRequest = {
                onDismiss.invoke()
            },
            text = { ErrorBottomSheet(msg, sheetState, onDismiss, false,component!!) },
            shape = RoundedCornerShape(Scale.CORNER_SMALL),
            confirmButton = {},
            dismissButton = {}
        )
    }
}


@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun ErrorBottomSheet(
    msg: String,
    sheetState: SheetState,
    onDismiss: () -> Unit,
    isSheetOpen: Boolean,
    component: CallConsultationComponent
) {

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .padding(16.dp)
        //.align(Alignment.CenterHorizontally)
    ) {
        Box(
            modifier = Modifier
                .fillMaxWidth(),
            contentAlignment = Alignment.Center
        ) {
            Column( horizontalAlignment = Alignment.CenterHorizontally,
                modifier = Modifier
                    .padding(horizontal = 16.dp)
                    .align(Alignment.Center)) {
                Text(
                    text = "Consultation Details",
                    style = MaterialTheme.typography.titleMedium,
                    color = MaterialTheme.colorScheme.onSurface,
                    textAlign = TextAlign.Center
                )
                Spacer(modifier = Modifier.height(8.dp))
                ExpertCard(
                    modifier = Modifier,
                    expertName = component.expertName,
                    expertImage = component.expertImage,
                    expertSkills = component.expertSkills
                )
            }

        }
        Spacer(modifier = Modifier.height(16.dp))
        Box(
            modifier = Modifier
                .padding(top = 16.dp, bottom = 20.dp)
                .fillMaxWidth(),
            contentAlignment = Alignment.Center
        ) {
            Column(
                horizontalAlignment = Alignment.CenterHorizontally,
                modifier = Modifier
                    .padding(horizontal = 16.dp)
                    .align(Alignment.Center)
            ) {
                Text(
                    text = "Oops!",
                    style = MaterialTheme.typography.headlineSmall.copy(
                        fontWeight = FontWeight.Bold,
                        color = MaterialTheme.colorScheme.primary
                    ),
                    textAlign = TextAlign.Center
                )
                Spacer(modifier = Modifier.height(8.dp))
                Text(
                    text = msg,
                    textAlign = TextAlign.Center,
                    style = MaterialTheme.typography.bodyMedium,
                    color = MaterialTheme.colorScheme.onBackground
                )
            }
        }

    }
}